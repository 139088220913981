<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="assignThree-container"
  >
    <assign-step :stepActive="3"></assign-step>
    <div class="common-grey-line"></div>

    <div class="scoped-conmon order-detail" v-if="assignOrderInfo">
      <div class="leftRight">
        <div class="assign-detail-text orderCode-height">
          <div class="orderCode-height">
            订单编号：
            <span class="order-code">{{
              assignOrderInfo.disOrderCode || "--"
            }}</span>
          </div>
          <div class="common-lableBg-blue" v-if="assignOrderInfo.companyName">
            {{ assignOrderInfo.companyName }}
          </div>
          <div class="common-lableBg-blue" v-if="assignOrderInfo.typeName">
            {{ assignOrderInfo.typeName }}
          </div>
        </div>

        <div class="blue-line"></div>
        <div class="assign-detail">
          <div class="assign-detail-col">
            <div class="assign-detail-title">
              <i class="el-icon-tickets"></i>订单信息
            </div>
            <div class="assign-detail-text">
              订单名称：{{ assignOrderInfo.disOrderName || "--" }}
            </div>
            <div class="assign-detail-text">
              通知人：<span class="common-lableBg-blue">{{
                assignOrderInfo.noticeName || "--"
              }}</span>
            </div>
          </div>
          <div class="assign-detail-col">
            <div class="assign-detail-title">
              <i class="el-icon-folder-add"></i> 附件
            </div>
            <div class="assign-detail-text detail-text-warp">
              <div
                v-if="
                  assignOrderInfo.fileList && assignOrderInfo.fileList.length
                "
              >
                <common-picture
                  v-for="(itemFile, fileIndex) in assignOrderInfo.fileList"
                  :key="fileIndex"
                  :fileUrl="itemFile.filedUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      itemFile.filedName.split('.')[
                        itemFile.filedName.split('.').length - 1
                      ]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="itemFile.filedName"
                ></common-picture>
              </div>
              <div v-else>--</div>
            </div>
          </div>
          <div class="assign-detail-col">
            <div class="assign-detail-title">
              <i class="el-icon-edit-outline"></i>备注
            </div>
            <div class="assign-detail-text">
              备注：{{ assignOrderInfo.remark || "--" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="assignOrderInfo && assignOrderInfo.shopList">
      <div
        class="shop-item"
        v-for="(item, index) in assignOrderInfo.shopList"
        :key="index"
      >
        <div class="common-grey-line"></div>
        <div class="scoped-conmon">
          <div class="leftRight">
            <div class="shopItem-title-conatiner">
              <div class="shopItem-title-left">
                <div class="shopItem-num">{{ index + 1 }}</div>
                <img
                  class="common-iconImg-MR"
                  v-if="item.shopLogo"
                  :src="item.shopLogo"
                  alt=""
                />
                <i v-else class="el-icon-user common-iconImg-MR"></i>
                <div class="shopName-platCategory">
                  <div>{{ item.shopName || "--" }}</div>
                  <div>{{ item.platformName }}/{{ item.categoryName }}</div>
                </div>
              </div>
              <div class="shopItem-title-right">
                <div class="rightItem">
                  <div>客户名称</div>
                  <div>{{ item.customerName || "--" }}</div>
                </div>
                <div class="rightItem">
                  <div>协议客服工作时长</div>
                  <div>{{ item.serviceWorkTime || "0" }}小时</div>
                </div>
                <div class="rightItem">
                  <div>协议子账号数量</div>
                  <div>{{ item.agreeSubNum || "0" }}个</div>
                </div>
              </div>
            </div>
          </div>
          <div class="leftRight">
            <div class="text-blue">{{ item.shopServiceDay || "0" }}天</div>
            <div class="text-black">
              {{ item.startTime || "" }} ~ {{ item.endTime || "" }}
            </div>
          </div>
        </div>
        <div class="blue-line"></div>
        <el-form label-width="140px" style="padding: 32px">
          <el-form-item label="服务内容 ">
            <el-radio
              v-if="
                item.serviceContent &&
                  item.serviceContent.split(',').includes('1')
              "
              :value="true"
              :label="true"
              >售前</el-radio
            >
            <el-radio
              v-if="
                item.serviceContent &&
                  item.serviceContent.split(',').includes('2')
              "
              :value="true"
              :label="true"
              >售中</el-radio
            >
            <el-radio
              v-if="
                item.serviceContent &&
                  item.serviceContent.split(',').includes('3')
              "
              :value="true"
              :label="true"
              >售后</el-radio
            >
            <el-radio
              v-if="
                item.serviceContent &&
                  item.serviceContent.split(',').includes('4')
              "
              :value="true"
              :label="true"
              >附加项</el-radio
            >
          </el-form-item>
          <el-form-item label="协议子账号 ">
            <div class="sub-List">
              <div class="subList-header">
                <div class="table-fontsize" style="width: 40px"></div>
                <div class="table-fontsize">子账号名称</div>
                <div class="table-fontsize">子账号密码</div>
                <div class="table-fontsize">管理员手机号</div>
                <div class="table-fontsize">指派客服</div>
                <div class="table-fontsize">服务时间</div>
                <div class="table-fontsize">备注</div>
                <div class="table-fontsize">创建人</div>
                <div class="table-fontsize">创建时间</div>
              </div>
              <div
                v-if="item.subAccountList && item.subAccountList.length"
                class="subList-content"
              >
                <div
                  class="subList-item"
                  v-for="(itemTwo, indexTwo) in item.subAccountList"
                  :key="indexTwo"
                >
                  <div
                    class="table-fontsize"
                    style="width: 40px;text-indent: 8px"
                  ></div>
                  <div class="table-fontsize">
                    {{ itemTwo.subAccountName || "--" }}
                  </div>
                  <div class="table-fontsize">
                    {{ itemTwo.subAccountPassword || "--" }}
                  </div>
                  <div class="table-fontsize">{{ itemTwo.phone || "--" }}</div>
                  <div class="table-fontsize">
                    {{ itemTwo.nickName || "--" }}
                  </div>
                  <div class="table-fontsize">
                    {{ itemTwo.startTime || "" }} ~ {{ itemTwo.endTime || "" }}
                  </div>
                  <div class="table-fontsize">{{ itemTwo.note || "--" }}</div>
                  <div class="table-fontsize">
                    {{ itemTwo.createName || "--" }}
                  </div>
                  <div class="table-fontsize">
                    {{ itemTwo.createTime || "--" }}
                  </div>
                </div>
              </div>
              <div v-else class="subList-content">
                <div style="text-align: center">暂无数据</div>
              </div>
            </div>
          </el-form-item>
          <br />
          <el-form-item label="派单金额 ">
            <div>
              <span style="font-size: 14px;color: #1890ff;">￥</span
              ><span class="text-blue">{{ item.price || "0" }}</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer-container">
      <div class="common-grey-line"></div>
      <div class="footer-content">
        <div>
          <el-button
            class="back-btn"
            plain
            @click="
              () => {
                $router.push(
                  `/assign-order/assign-list/assign-two?id=${
                    this.$route.query.id
                  }${
                    this.$route.query.isEdit
                      ? '&isEdit=' + this.$route.query.isEdit
                      : ''
                  }`
                );
              }
            "
            >上一步：供应商信息填报</el-button
          >
          <el-button
            class="next-btn"
            type="primary"
            @click="
              () => {
                saveAssignOrder();
              }
            "
            >确认派单</el-button
          >
        </div>
        <div>
          <!-- <el-button class="next-btn" plain>重置此页</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssignStep from "./children/AssignStep.vue";
import {
  assignOrderDetail,
  saveAssignOrder
} from "../../service/assignOrder.js";
import CommonPicture from "../../components/common/commonPicture.vue";
import { Config } from "../../utils/index.js";
export default {
  components: {
    AssignStep,
    CommonPicture
  },
  data() {
    return {
      Config,
      loading: false,
      assignOrderInfo: {}
    };
  },
  created() {
    this.assignOrderDetail();
  },
  methods: {
    async saveAssignOrder() {
      // 确认信息后，生成订单
      this.loading = true;
      let { code } = await saveAssignOrder({
        disOrderNo: this.$route.query.id,
        stepNum: 3
      });
      this.loading = false;
      if (code == 200) {
        this.$message.success("派单成功");
        this.$router.push(`/assign-order/assign-list`);
      }
    },
    async assignOrderDetail() {
      let { data } = await assignOrderDetail({
        disOrderNo: this.$route.query.id
      });
      this.assignOrderInfo = data;
    }
  }
};
</script>
<style lang="less" scoped>
.el-icon-edit-outline,
.el-icon-folder-add,
.el-icon-tickets {
  font-size: 24px;
  margin-right: 16px;
  color: #1890ff;
}
.common-iconImg-MR {
  height: 72px;
  width: 72px;
  line-height: 72px;
  font-size: 56px;
  border: 1px solid #b9b9b9;
}
.assignThree-container {
  text-align: left;
  position: relative;
  min-height: 100%;
  background: #fff;
  padding-bottom: 84px;
  .text-blue {
    color: #1890ff;
    font-size: 36px;
    vertical-align: middle;
  }
  .sub-List {
    border-radius: 4px;
    border: 1px solid #eaeaeb;
    max-height: 352px;
    position: relative;
    width: 100%;
    .table-fontsize {
      font-size: 14px;
      width: 170px;
    }
    .subList-header {
      height: 50px;
      width: 100%;
      background: #f2f2f2;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .subList-content {
      margin-top: 50px;
      max-height: 300px;
      overflow-y: scroll;
      width: 100%;
      .subList-item {
        border-bottom: solid 1px #eaeaeb;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }
  }
  .order-detail {
    padding: 0 16px 32px 16px !important;
    .leftRight {
      border: none !important;
      &:nth-of-type(1) {
        width: 100% !important;
        .assign-detail {
          background: rgba(24, 144, 255, 0.08);
          padding: 22px 44px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          .assign-detail-col {
            width: 30%;
            .assign-detail-title {
              font-size: 20px;
              color: #1890ff;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
            }
            .assign-detail-text {
              width: 100%;
              line-height: 32px;
            }
          }
        }
      }
    }
    .order-code {
      color: #1890ff;
      font-size: 24px;
      margin-right: 32px;
    }
    .orderCode-height {
      height: 100px;
      line-height: 100px;
      font-size: 24px;
      margin-left: 50px;
      display: flex;
      align-items: center;
    }
  }
  .scoped-conmon {
    width: 100%;
    box-sizing: border-box;
    padding: 32px 0px 32px 40px;
    display: flex;
    align-items: center;
    .leftRight {
      &:nth-of-type(1) {
        width: 66%;
        border-right: 1px solid #bbb;
        .shopItem-title-conatiner {
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          padding-right: 90px;
          .shopItem-title-right {
            display: flex;
            align-items: center;
            .rightItem {
              margin-left: 89px;
              div {
                color: #333;
                &:nth-of-type(1) {
                  font-size: 14px;
                  margin-bottom: 12px;
                }
                &:nth-of-type(2) {
                  font-size: 28px;
                }
              }
            }
          }
          .shopItem-title-left {
            display: flex;
            align-items: center;
            .shopName-platCategory {
              color: #333;
              div {
                &:nth-of-type(1) {
                  font-size: 24px;
                  margin-bottom: 12px;
                }
                &:nth-of-type(2) {
                  font-size: 16px;
                }
              }
            }
            .shopItem-num {
              width: 58px;
              height: 58px;
              border-radius: 50%;
              color: #fff;
              font-size: 32px;
              background: #1890ff;
              text-align: center;
              line-height: 58px;
              font-weight: 700;
              margin-right: 34px;
            }
          }
        }
        .assign-detail {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 32px;
          .assign-detail-text {
            width: 48%;
            line-height: 32px;
          }
          .detail-text-warp {
            display: flex;
          }
        }
      }
      &:nth-of-type(2) {
        width: 22%;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .text-black {
          color: #333;
          font-size: 14px;
        }
        .text-blue {
          color: #1890ff;
          font-size: 42px;
        }
      }
    }
  }
  .footer-container {
    height: 100px;
    width: 100%;
    // box-sizing: border-box;
    padding: 0 16px;
    background: #fff;
    position: absolute;
    bottom: -16px;
    right: -16px;
    z-index: 9999;
    .footer-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      height: 84px;
      align-items: center;
      box-sizing: border-box;
      padding-right: 120px;
      padding-left: 100px;
      .back-btn {
        height: 38px;
        border: 1px solid #1890ff;
        color: #1890ff;
        margin-right: 16px;
      }
      .next-btn {
        height: 38px;
      }
    }
  }
  .blue-line {
    height: 1px;
    width: 120%;
    margin: 0 0 0 -10%;
    background: #1890ff;
  }
}
</style>
