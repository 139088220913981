import { render, staticRenderFns } from "./assignThree.vue?vue&type=template&id=58935272&scoped=true&"
import script from "./assignThree.vue?vue&type=script&lang=js&"
export * from "./assignThree.vue?vue&type=script&lang=js&"
import style0 from "./assignThree.vue?vue&type=style&index=0&id=58935272&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58935272",
  null
  
)

export default component.exports